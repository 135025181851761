/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  font: inherit;
  font-size: 100%;
  line-height: 1;
  vertical-align: baseline;
  border: 0;
  box-sizing: border-box;
}

ol, ul, li {
  margin: 0;
  padding:0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  border: 0;
  list-style: none;
}

*, *::before, *::after {
  box-sizing: border-box;
}

select {
  font-family: var(--font-lato);
  line-height: 1;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  font-family: Roboto, sans-serif;
  color: #524965;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  background-color: var(--color-white);
  width: 100%;
}
.main_content.fixed{
  filter: blur(2px);
}
blockquote, q {
  quotes: none;
}

blockquote::before, blockquote::after,
q::before, q::after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  font: inherit;
  line-height: normal;
  text-align: left;
  color: inherit;
  background: transparent;
  border: none;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}
.main_container{
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}
.main_container_fix{
  padding: 17px 15px;
  height: calc(100vh - 79px);
  overflow: auto;
}
.main_container-inner_fix{
  width: 1440px;
  margin: 0 auto;
  max-width: 100%;
}

hr{
  color: #524965;
  margin: 40px 0;
}
.t-hidden{
  display: none;
}