.Toastify__toast {
    padding: 0;
    border-radius: 0;
    background: none !important;
    box-shadow: none !important;
}

.Toastify__toast-container {
    width: 550px !important;

    @media screen and (max-width: 600px) {
        width: 100% !important;
    }
}

.Toastify__toast-container--top-right {
    right: 0 !important;
}

.toast-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 1em;
    background: white;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.05);
    border-radius: 4px;

    &.-success {
        background-color: #44a45f;
    }

    &.-error {
        background-color: #e6173e;
    }

    &.-warning {
        background-color: #fceb3b;
        color: #000;
    }

    .close__icon {
        min-width: 25px;
        &.-warning {
            color: #000;
        }
    }

    &-toast {
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;

        &--icon {
            margin-right: 20px;
            min-width: 24px;

            &.-success {
                color: #fff;
            }

            &.-error {
                color: #fff;
            }

            &.-warning {
                color: #000;
            }
        }
    }
}
