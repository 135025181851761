:root {
  /* Font Families */
  --font-roboto: 'Roboto', sans-serif;

  /* Font Weights */
  --weight-regular: 400;
  --weight-bold: 700;

  --color-white: #ffffff;
  --color-brand: #5B765F;
  --color-brandDark: #183f31;
  --color-brandLight: #f2eeeb;
  --color-gray7: #80807F;
  --color-gray9: #ABA293;
  --color-highlighter: #e0ff32;

  /* Global Measurements */
  --button-border-radius: 8px;

  /* TRANSITION */
  --global-transition: .3s ease;
  --button-transition: .1s all ease-in-out;
}