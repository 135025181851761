/* Roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  src:  local('Roboto'), local('Roboto'),
  url('../fonts/Roboto-Regular.ttf') format('truetype') /* Chrome 26+, Opera 23+, Firefox 39+ */
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src:  local('Roboto Medium'), local('Roboto-Medium'),
  url('../fonts/Roboto-Medium.ttf') format('truetype') /* Chrome 26+, Opera 23+, Firefox 39+ */
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src:  local('Roboto Bold'), local('Roboto-Bold'),
  url('../fonts/Roboto-Bold.ttf') format('truetype') /* Chrome 26+, Opera 23+, Firefox 39+ */
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src:  local('Roboto Black'), local('Roboto-Black'),
  url('../fonts/Roboto-Black.ttf') format('truetype') /* Chrome 26+, Opera 23+, Firefox 39+ */
}

